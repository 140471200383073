import React from 'react'
import { useState, useEffect } from 'react';


export function calculateTimeLeft() {
    const end = "October 30 2021 16:59:59 GMT+0100";
    const difference = +Date.parse(end) - +Date.parse(new Date());
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    console.log(timeLeft);
    return timeLeft;
  }
  
  export default function App() {
    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  
    React.useEffect(() => {
      const id = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => {
        clearTimeout(id);
      };
    });
  
    const timerComponents = Object.keys(timeLeft).map(interval => {
      if (!timeLeft[interval]) {
        return;
      }
      
      let letter = interval.substring(0,1);
      return (
         <span>
          {timeLeft[interval]}{letter+" "}
        </span>
      )
    });
  
    return (
      <div>
        <h2 style={{color: "#e86426"}}>{timerComponents.length ? timerComponents : ""}</h2>
      </div>
    );
  }
  