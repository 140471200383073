import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";
import Countdown, { calculateTimeLeft } from "./Countdown";

export const StyledButton = styled.button`
  padding: 8px;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("- Are you ready?");


  const claimNFTs = (_amount) => {
    setClaimingNft(true);
    setFeedback("- Are you ready?");
    blockchain.smartContract.methods.mintPixelHuman(_amount).send({
      from: blockchain.account,
      value: blockchain.web3.utils.toWei((0.04 * _amount).toString(), "ether"),
    }).once("error", (err) => {
      console.log(err);
      setFeedback("- Claiming was interrupted...");
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    }).then((receipt) => {
      setFeedback("- Minting success!");
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    });
  };



  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (
    <s.Screen>
      <s.SpacerMedium />
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container flex={1} ai={"center"} jc={"center"}>
          {Object.keys(calculateTimeLeft()).length === 0 ?
          <h1>READY TO MINT!</h1>
          :
          <h1>MINTING COMING SOON</h1>
          }
          <s.SpacerSmall />
          <Countdown></Countdown>
          <s.SpacerSmall />
          <button type="button" class="btn-color"
          disabled={Object.keys(calculateTimeLeft()).length === 0 ? 0 : 1}
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            CONNECT
          </button>
          <s.SpacerSmall />
          {blockchain.errorMsg !== "" ? (
            <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
          ) : null}
        </s.Container>
      ) : (
        <s.Container flex={1} ai={"center"} jc={"center"}>
          {data.paused ?
          (<s.TextTitle style={{ textAlign: "center" }}>
            Minting will soon be available...
          </s.TextTitle>)
          :
          (<s.TextTitle style={{ textAlign: "center" }}>
            Claimed: {data.totalSupply}/8000 {feedback}
            <s.SpacerSmall />
            <label style={{paddingRight: "10px"}} for="howMany">Claim: </label>
            <select id="howMany" name="select">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
            </select>
          </s.TextTitle>)
          }
          <s.SpacerXSmall />
          <button 
          disabled={(claimingNft || data.paused) ? 1 : 0}
          type="button" class="btn-color"
            onClick={(e) => {
              e.preventDefault();
              if (document.getElementById("howMany")) {
                claimNFTs(document.getElementById("howMany").value);
              }
            }}
          >
            {claimingNft ? "Busy claiming..." : "MINT NOW!"}
          </button>
          <s.SpacerSmall />
        </s.Container>
      )}
    </s.Screen>
  );
}

export default App;
